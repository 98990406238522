import React from 'react';
import { graphql } from 'gatsby';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import Layout from '@components/layout';
import { PageMeta } from '@components/pageMeta';
import { Intro } from '@components/portals/artists/intro';
import { Content } from '@components/portals/artists/content';
import { OtherArtists } from '@components/portals/otherArtists';

const ArtistTemplate = ({ data: { page } }) => {
  const { seo, slug } = page || {};

  return (
    <Layout>
      <PageMeta {...seo} />
      <Intro {...page} />
      <Content {...page} />
      <OtherArtists currentSlug={slug?.current} />
    </Layout>
  );
};

export default ArtistTemplate;

export const artistQuery = graphql`
  query standardArtistQuery($slug: String!) {
    page: sanityArtist(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      slug {
        current
      }
      name
      position
      photo {
        ...ImageWithPreview
      }
      content {
        ...ArtistsModules
      }
    }
  }
`;
