import React from 'react';

import { useArtistQuery } from '@querys/useArtistQuery';
import { StandardCarousel } from '../pageModules/carousels/standardCarousel';
import { usePortalQuery } from '@querys/usePortalQuery';

export const OtherArtists = ({ currentSlug }) => {
  const { artistPortal } = usePortalQuery();
  const { artists } = useArtistQuery();

  //filter currentSlug from nodes
  const filteredNodes = artists
    ?.filter((node) => node?.slug?.current !== currentSlug)
    .slice(0, 6);
  const slider = { slides: filteredNodes };
  return (
    <div className='pt-space-m pb-space-m relative z-10'>
      <StandardCarousel
        slider={slider}
        label='Other artists'
        viewAll={artistPortal?.slug?.current}
      />
    </div>
  );
};
