import React from 'react';
import { SanityImage } from '@components/sanity/sanityImage';

export const Intro = ({ name, position, photo }) => {
  return (
    <div className='relative z-10 pt-space-l px-gutter text-center text-teal mt-12 lg:mt-0'>
      <span className='blockH6'>{position}</span>
      <h1 className='blockH1 mt-3 lg:mt-7'>{name}</h1>
      <div className='mt-12 lg:mt-20 relative aspect-w-16 aspect-h-10'>
        <SanityImage
          image={photo}
          className='rounded-3xl absolute top-0 left-0 w-full h-full object-cover'
        />
      </div>
    </div>
  );
};
