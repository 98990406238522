import React from 'react';
import { useLocation } from '@reach/router';

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

export const Share = () => {
  const { href } = useLocation();
  return (
    <div className='flex flex-col gap-y-2 richH6 text-grey items-start'>
      <span>Share:</span>
      <FacebookShareButton url={href}>
        <span className='underline'>Facebook</span>
      </FacebookShareButton>
      <TwitterShareButton url={href}>
        <span className='underline'>Twitter</span>
      </TwitterShareButton>
      <LinkedinShareButton url={href}>
        <span className='underline'>LinkedIn</span>
      </LinkedinShareButton>
      <EmailShareButton url={href}>
        <span className='underline'>Email</span>
      </EmailShareButton>
    </div>
  );
};
