import { graphql, useStaticQuery } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useArtistQuery = () => {
  const { allSanityArtist } = useStaticQuery(graphql`
    query {
      allSanityArtist {
        artists: nodes {
          name
          position
          bio
          photo {
            ...ImageWithPreview
          }
          slug {
            current
          }
        }
      }
    }
  `);
  return allSanityArtist || {};
};
