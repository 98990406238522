import React from 'react';

import ModuleZone from '~moduleZone';
import { Share } from '../share';

export const Content = ({ content }) => {
  return (
    <div className='grid grid-cols-14 relative z-10 pt-space-m pb-space-s'>
      <div className='col-start-1 col-end-15 lg:col-end-12'>
        {content && <ModuleZone {...content} />}
      </div>

      <div className='col-start-2 lg:col-start-12 col-end-14 mt-12 lg:mt-0'>
        <Share />
      </div>
    </div>
  );
};
